<template>
  <v-container fluid class="py-10">
    <v-row dense justify="center">
      <v-col class="centerAlign"
        ><base-body customText="true"
          ><h1>{{ title }}</h1></base-body
        ></v-col
      ></v-row
    >
    <v-row dense justify="center"
      ><v-col class="centerAlign"
        ><base-body customText="true"
          ><h4>{{ subtitle }}</h4>
        </base-body></v-col
      ></v-row
    >
    <v-row justify="center" dense
      ><v-col class="centerAlign"
        ><base-body customText="true">
          <div v-html="div"></div> </base-body></v-col
    ></v-row>
  </v-container>
</template>

<script>
export default {
  name: "SectionTools",

  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    div: {
      type: String,
      required: true,
    },
  },
};
</script>
<style scoped>
.centerAlign {
  text-align: center;
}
</style>
